// import $ from "jquery"; // not always available
import { HTML_ATTRIBUTE_COOKIE_IDS, OPT_IN_CONTENT_BLOCKER_ALL } from "..";
const ALREADY_OVERWRITTEN_PROPERTY = "listenOptInJqueryFnForContentBlockerNow";
/**
 * Modify a jQuery function to wait for consent if a content blocker is within the
 * given selector.
 */

function hijackJqueryFn(fns) {
  const $ = window.jQuery;

  if (!($ !== null && $ !== void 0 && $.fn)) {
    return;
  }

  const jQueryFns = $.fn;

  for (const fn of fns) {
    const originalFunction = jQueryFns[fn];

    if (!originalFunction) {
      continue;
    } // Already overwritten?


    const alreadyOverwritten = jQueryFns[ALREADY_OVERWRITTEN_PROPERTY] = jQueryFns[ALREADY_OVERWRITTEN_PROPERTY] || [];

    if (alreadyOverwritten.indexOf(fn) > -1) {
      continue;
    }

    alreadyOverwritten.push(fn); // Memorize the static properties (e.g. `jQuery.fn.{fn}.defaults` (https://github.com/marioestrada/jQuery-gMap/blob/33b2771a5789b7531eb56878cfa3e4a0c30a8c43/jquery.gmap.js#L250))

    const staticProps = Object.getOwnPropertyDescriptors(originalFunction);
    delete staticProps.length;
    delete staticProps.name;
    delete staticProps.prototype;

    jQueryFns[fn] = function (...args) {
      if (this.length) {
        const notBlockedElements = [];

        for (const element of this.get()) {
          const blockedElements = Array.prototype.slice.call(element.querySelectorAll("[".concat(HTML_ATTRIBUTE_COOKIE_IDS, "]"))); // Also respect own element

          if (element.getAttribute(HTML_ATTRIBUTE_COOKIE_IDS)) {
            blockedElements.push(element);
          } // Call original function immediate if no content blocker found


          if (blockedElements.length) {
            // Wait for all unblocked content
            // TODO: should we also create chunks per `consent-id`?
            Promise.all(blockedElements.map(node => new Promise(resolve => node.addEventListener(OPT_IN_CONTENT_BLOCKER_ALL, resolve)))).then(() => originalFunction.apply($(element), args));
          } else {
            notBlockedElements.push(element);
          }
        }

        return originalFunction.apply(jQuery(notBlockedElements), args);
      } else {
        // There is no jQuery object passed to the jQuery function. But we need to call the
        // original function anyway as some properties of e.g. `$.fn.prettyPhoto` can be
        // lazy loaded and expect a "first" call
        return originalFunction.apply($(this), args);
      }
    }; // Apply memorized static properties


    Object.defineProperties(jQueryFns[fn], staticProps);
  }
}

export { hijackJqueryFn };