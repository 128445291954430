import { OPT_IN_CONTENT_BLOCKER_ALL, OPT_IN_CONTENT_BLOCKER, HTML_ATTRIBUTE_BLOCKER_ID } from "@devowl-wp/headless-content-unblocker";
const MEMORIZE_ELEMENTOR_HANDLER_PROPERTY = "rcb-overwritten";

function overwrite(prototype, {
  delay,
  optIn,
  optInAll
}) {
  const {
    onInit,
    [MEMORIZE_ELEMENTOR_HANDLER_PROPERTY]: already
  } = prototype;

  if (already) {
    return;
  }

  prototype[MEMORIZE_ELEMENTOR_HANDLER_PROPERTY] = true; // `onInit` is called for each element, event it is blocked

  prototype.onInit = function (...args) {
    const $element = this.$element;
    const element = $element.get(0);

    if ($element.attr(HTML_ATTRIBUTE_BLOCKER_ID)) {
      $element.attr(MEMORIZE_ELEMENTOR_HANDLER_PROPERTY, "1");
      element.addEventListener(OPT_IN_CONTENT_BLOCKER, ({
        detail
      }) => {
        optIn === null || optIn === void 0 ? void 0 : optIn($element, detail, this);
      });
      element.addEventListener(OPT_IN_CONTENT_BLOCKER_ALL, ({
        detail
      }) => {
        optInAll === null || optInAll === void 0 ? void 0 : optInAll($element, detail, this);
        setTimeout(() => onInit.apply(this, args), delay || 0);
      });
    } else {
      return onInit.apply(this, args);
    }
  };
}
/**
 * Overwrite Elementor modules and wait until consent is given for blocked module.
 * Passed `handlers` can be widget names like `video.default` or class names `VideoPlaylistHandler`
 * for dynamic modules.
 */


function hijackElementorOnInit(handlers) {
  window.addEventListener("elementor/frontend/init", async () => {
    const {
      elementorFrontend: {
        elementsHandler
      }
    } = window;

    for (const handler of handlers) {
      // Let's try to hijack `addHandler` for dynamic modules
      const originalAddHandler = elementsHandler.addHandler;

      elementsHandler.addHandler = function (handlerClass, ...rest) {
        if (handlerClass.name === handler.className) {
          overwrite(handlerClass.prototype, handler);
        }

        return originalAddHandler.apply(this, [handlerClass, ...rest]);
      };
    }
  });
}

export { hijackElementorOnInit, MEMORIZE_ELEMENTOR_HANDLER_PROPERTY };