const APIS = ["youtube", "vimeo"];
/**
 * Overwrite the video API of elementor to make it GDPR compliant. It also implements
 * auto-play mechanism when clicking the content blocker.
 *
 * @see https://github.com/elementor/elementor/pull/12567
 * @see https://git.io/Js1em
 * @see https://git.io/Js1Eu
 */

function overwriteElementorVideoApi() {
  window.addEventListener("elementor/frontend/init", async () => {
    const {
      elementorFrontend
    } = window; // Override Video API's and check for consent

    elementorFrontend.on("components:init", () => {
      for (const apiName of APIS) {
        const api = elementorFrontend.utils[apiName];

        if (api) {
          api.insertAPI = function () {
            const apiUrl = this.getApiURL();
            window.consentApi.unblock(apiUrl).then(() => {
              jQuery("body").append(jQuery("<script>", {
                src: apiUrl
              }));
            });
            this.setSettings("isInserted", true);
          };
        }
      }
    });
  });
}

export { overwriteElementorVideoApi };